<template>
  <div class="container">
    <div class="logo">
      <img :src="'assets/logo.svg'" alt="">
    </div>
    <div class="time"><DigitalClock color="white"/></div>
  </div>

  <div id="app">
    <Calendar />
  </div>
</template>
<script>
import Calendar from './components/Calendar.vue'
import DigitalClock from './components/DigitalClock.vue'
export default {
  name: 'app',
  components: {
    Calendar,
    DigitalClock
  }
}
</script>
<style>
body{
  background:#000;
  margin:0;
  padding:0;
}
.logo{
  float:left;
  padding: 1vw;
  }
  .logo img{
    padding-top:1vw;
    min-height: 3vw;
  }
.time{
  padding-top:1vw;
  padding-right:1vw;
  float:right;
  font-weight: bold;
}
table{
  border:none;
  border-collapse: collapse;
}
table th{
  text-align:left;
  /* vertical-align: center; */
  padding-left:10px;
  background: #112129;
  padding: 20px 0px 20px 10px;
}
table td{
  text-align:left;
  padding:0;
  vertical-align: middle;
  padding: 10px 0px 10px 10px;
  border-bottom: solid 2px #444;
  line-height: 100%;
}
.table-court{
  white-space: nowrap;
  font-weight: bold;
  text-align: center;
}
.table-time{
  vertical-align: middle;
}
.customer-item-image{
  text-align: center;
}
.customer-item-image img{
  max-height: 5vw;
  border-radius: 50% !important;
}
.table-time{
  font-weight: bold;
  font-size: 80%;
  white-space:nowrap;
}
#app{
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 2vw;
  color:#fff;
}
.customer-item{
   display: flex;
   align-items:center;
  }
  .customer-item-image{
    margin-right: 10px;
    display: flex;
    vertical-align: center;
    align-items:center;
    height:100%
  }
  .customer-item-title{
    line-height:100%;
  }
  .customer-versus{
    width:100px;
    text-align: center;
  }
  .customer-icon-vs{
    width: 30px;
    margin-right: 5px;
  }
</style>